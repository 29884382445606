import React, { useState, useEffect } from 'react';
import WatchWordChoosePictureQuiz from './WatchWordChoosePictureQuiz';
import FillInTheBlankQuiz from './FillInTheBlankQuiz';
import WatchPictureChooseWordQuiz from './WatchPictureChooseWordQuiz';
import WatchPictureTypingWordQuiz from './WatchPictureTypingWordQuiz';
import Result from './Result';
import {useParams} from "react-router-dom"; // Import the new Result component

const Quiz = () => {
  const { topicId } = useParams(); // Get the topic ID from the URL
  const [quizData, setQuizData] = useState([]);
  const [currentQuizIndex, setCurrentQuizIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showResult, setShowResult] = useState(false);
  const [quizTransactionId, setQuizTransactionId] = useState(null); // Store the transaction ID

  // Fetch quiz data and start quiz session
  useEffect(() => {
    const fetchQuizData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/quiz?topic_id=${topicId}`);

        if (!response.ok) {
          throw new Error('Failed to fetch quiz data');
        }

        const data = await response.json();
        const filteredData = data.filter(
            (quiz) => quiz.type === 'watch_word_choose_picture' || quiz.type === 'fill_in_the_blank' || quiz.type === 'watch_picture_choose_word' || quiz.type === 'watch_picture_typing_word'
        );

        setQuizData(filteredData);
        const totalQuestions = filteredData.length;

        const transactionId = await startQuizSession(1, totalQuestions); // Assuming user_id = 1, replace as needed
        setQuizTransactionId(transactionId); // Store the transaction ID

        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchQuizData();
  }, []);

  // Function to start quiz session by making a POST request
  const startQuizSession = async (userId, totalQuestions) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/start_quiz`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userId,
          total_questions: totalQuestions,
        }),
      });

      const result = await response.json();

      if (result.status === 'success') {
        console.log('Quiz started! Transaction ID:', result.transaction_id);
        return result.transaction_id; // Return the transaction ID
      } else {
        console.error('Error starting quiz:', result.message);
        return null;
      }
    } catch (error) {
      console.error('An error occurred while starting the quiz:', error);
      return null;
    }
  };

  if (loading) {
    return <div>Loading quiz...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (quizData.length === 0) {
    return <div>No quiz data available</div>;
  }

  const totalQuizzes = quizData.length;
  const currentQuiz = quizData[currentQuizIndex];

  const handleNext = () => {
    if (currentQuizIndex === totalQuizzes - 1) {
      setShowResult(true);
    } else {
      setCurrentQuizIndex(currentQuizIndex + 1);
    }
  };

  const handleAnswer = (isCorrect) => {
    if (isCorrect) {
      setScore(score + 1);
    }
    handleNext();
  };

  if (showResult) {
    return <Result score={score} quizTransactionId={quizTransactionId} />; // Pass score and transaction ID
  }

  return (
      <div style={styles.container}>
        <h1>Quiz Page</h1>
        <div style={styles.quizContainer}>
          {currentQuiz.type === 'watch_word_choose_picture' && (
              <WatchWordChoosePictureQuiz
                  question={currentQuiz.question}
                  word={currentQuiz.word}
                  choices={currentQuiz.choices}
                  correct_answer={currentQuiz.correct_answer}
                  handleAnswer={handleAnswer}
              />
          )}
          {currentQuiz.type === 'fill_in_the_blank' && (
              <FillInTheBlankQuiz
                  question={currentQuiz.question}
                  sentence={currentQuiz.sentence}
                  choices={currentQuiz.choices}
                  correct_answer={currentQuiz.correct_answer}
                  handleAnswer={handleAnswer}
              />
          )}
          {currentQuiz.type === 'watch_picture_choose_word' && (
              <WatchPictureChooseWordQuiz
                  question={currentQuiz.question}
                  choices={currentQuiz.choices}
                  correct_answer={currentQuiz.correct_answer}
                  image_url={currentQuiz.image_url}
                  handleAnswer={handleAnswer}
              />
          )}
          {currentQuiz.type === 'watch_picture_typing_word' && (
              <WatchPictureTypingWordQuiz
                  question={currentQuiz.question}
                  image_url={currentQuiz.image_url}
                  correct_answer={currentQuiz.correct_answer}
                  handleAnswer={handleAnswer}
              />
          )}
          <p>Question {currentQuizIndex + 1} of {totalQuizzes}</p>
        </div>
      </div>
  );
};

// Simple styles for the quiz page
const styles = {
  container: {
    textAlign: 'center',
    marginTop: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  quizContainer: {
    border: '1px solid #ddd',
    padding: '20px',
    borderRadius: '10px',
    width: '50%',
    margin: '0 auto',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#f9f9f9',
  },
};

export default Quiz;