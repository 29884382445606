// src/components/Learn.js
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

const Learn = () => {
  const { topicId } = useParams(); // Get the topic ID from the URL
  const [flashcards, setFlashcards] = useState([]);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFlashcards = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/vocabularies?topic_id=${topicId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch flashcards');
        }
        const data = await response.json();
        setFlashcards(data); // Assuming the API returns an array of flashcards
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchFlashcards();
  }, [topicId]);

  // Function to play the text-to-speech pronunciation of the word
  const playTextToSpeech = (word) => {
    if ('speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(word);
      utterance.lang = 'en-US'; // Set the language
      window.speechSynthesis.speak(utterance);
    } else {
      console.error("Text-to-Speech is not supported in this browser.");
    }
  };

  // Auto-play TTS whenever a new flashcard is displayed
  useEffect(() => {
    if (flashcards.length > 0) {
      playTextToSpeech(flashcards[currentCardIndex].word);
    }
  }, [currentCardIndex, flashcards]);

  const handleNext = () => {
    if (currentCardIndex < flashcards.length - 1) {
      setCurrentCardIndex(currentCardIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentCardIndex > 0) {
      setCurrentCardIndex(currentCardIndex - 1);
    }
  };

  if (loading) {
    return <div>Loading flashcards...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (flashcards.length === 0) {
    return <div>No flashcards available</div>;
  }

  const currentCard = flashcards[currentCardIndex];

  return (
      <div style={styles.container}>
        <h1>Learn English Flashcards</h1>
        <div style={styles.flashcard}>
          <h2>{currentCard.word}</h2>
          <button onClick={() => playTextToSpeech(currentCard.word)} style={styles.audioButton}>
            🔊 {/* Speaker icon */}
          </button>
          <p><strong>Pronunciation:</strong> {currentCard.pronunciation}</p>
          <p><strong>Translation:</strong> {currentCard.translate}</p>
          <img src={currentCard.image_url} alt={currentCard.word} style={styles.image} />
          <p><strong>Definition:</strong> {currentCard.definition}</p>
          <p><strong>Example Sentence:</strong> "{currentCard.example_sentence}"</p>
          <p>Flashcard {currentCardIndex + 1} of {flashcards.length}</p>
        </div>
        <div style={styles.buttons}>
          <button onClick={handlePrevious} disabled={currentCardIndex === 0}>Previous</button>
          <button onClick={handleNext} disabled={currentCardIndex === flashcards.length - 1}>Next</button>
        </div>
      </div>
  );
};

// Updated styles for the Learn component
const styles = {
  container: {
    textAlign: 'center',
    marginTop: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  flashcard: {
    border: '1px solid #ddd',
    padding: '20px',
    borderRadius: '10px',
    width: '50%',
    margin: '0 auto',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#f9f9f9',
  },
  image: {
    width: '200px',
    height: '200px',
    objectFit: 'cover',
    margin: '20px 0',
  },
  buttons: {
    marginTop: '20px',
  },
  audioButton: {
    border: 'none',
    background: 'transparent',
    fontSize: '24px',
    cursor: 'pointer',
    marginBottom: '10px',
  },
};

export default Learn;