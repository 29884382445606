// src/components/LearnQuiz.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const LearnQuiz = () => {
    const { topicId } = useParams(); // Get the topic ID from the URL
    const [flashcards, setFlashcards] = useState([]);
    const [currentCardIndex, setCurrentCardIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userInput, setUserInput] = useState('');
    const [feedback, setFeedback] = useState('');

    useEffect(() => {
        const fetchFlashcards = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/vocabularies?topic_id=${topicId}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch flashcards');
                }
                const data = await response.json();
                setFlashcards(data); // Assuming the API returns an array of flashcards
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchFlashcards();
    }, [topicId]);

    // Function to play the text-to-speech pronunciation of the word or definition
    const playTextToSpeech = (text) => {
        if ('speechSynthesis' in window) {
            const utterance = new SpeechSynthesisUtterance(text);
            utterance.lang = 'en-US'; // Set the language
            window.speechSynthesis.speak(utterance);
        } else {
            console.error("Text-to-Speech is not supported in this browser.");
        }
    };

    // Auto-play the definition whenever a new flashcard is displayed
    useEffect(() => {
        if (flashcards.length > 0) {
            playTextToSpeech(flashcards[currentCardIndex].definition);
        }
    }, [currentCardIndex, flashcards]);

    const handleNext = () => {
        setUserInput('');
        setFeedback('');
        if (currentCardIndex < flashcards.length - 1) {
            setCurrentCardIndex(currentCardIndex + 1);
        } else {
            // Loop back to the first card
            setCurrentCardIndex(0);
        }
    };

    const handleGuess = () => {
        const currentWord = flashcards[currentCardIndex].word.toLowerCase();
        if (userInput.toLowerCase().trim() === currentWord) {
            setFeedback('Correct!');
        } else {
            setFeedback(`Wrong! The correct answer is: ${flashcards[currentCardIndex].word}`);
        }
    };

    if (loading) {
        return <div>Loading flashcards...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (flashcards.length === 0) {
        return <div>No flashcards available</div>;
    }

    const currentCard = flashcards[currentCardIndex];

    return (
        <div style={styles.container}>
            <h1>Learn English Flashcards Quiz</h1>
            <div style={styles.flashcard}>
                <p><strong>Definition:</strong> {currentCard.definition}</p>
                <button onClick={() => playTextToSpeech(currentCard.word)} style={styles.audioButton}>
                    🔊 {/* Speaker icon */}
                </button>
            </div>
            <div style={styles.interaction}>
                <input
                    type="text"
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    placeholder="Guess the word"
                />
                <button onClick={handleGuess}>Submit</button>
                <p>{feedback}</p>
                {feedback === 'Correct!' && (
                    <button onClick={handleNext}>Next</button>
                )}
            </div>
            <p>Flashcard {currentCardIndex + 1} of {flashcards.length}</p>
        </div>
    );
};

const styles = {
    container: {
        textAlign: 'center',
        marginTop: '20px',
        fontFamily: 'Arial, sans-serif',
    },
    flashcard: {
        border: '1px solid #ddd',
        padding: '20px',
        borderRadius: '10px',
        width: '50%',
        margin: '0 auto',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#f9f9f9',
        cursor: 'pointer',
        marginBottom: '20px',
    },
    interaction: {
        marginTop: '20px',
    },
    buttons: {
        marginTop: '20px',
    },
    audioButton: {
        border: 'none',
        background: 'transparent',
        fontSize: '24px',
        cursor: 'pointer',
        marginBottom: '10px',
    },
};

export default LearnQuiz;