// src/components/History.js
import React from 'react';

const History = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '20%' }}>
            <h1>History Page</h1>
        </div>
    );
};

export default History;