// src/components/TopicList.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const TopicList = () => {
    const [topics, setTopics] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    // Extract the query parameters to determine mode
    const searchParams = new URLSearchParams(location.search);
    const mode = searchParams.get("mode") || "learn";

    useEffect(() => {
        const fetchTopics = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/topics`);
                if (!response.ok) {
                    throw new Error('Failed to fetch topics');
                }
                const result = await response.json();
                setTopics(result);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchTopics();
    }, []);

    const handleTopicClick = (topicId) => {
        if (mode === 'learn') {
            navigate(`/learn/${topicId}`);
        } else if (mode === 'quiz') {
            navigate(`/quiz/${topicId}`);
        }else if (mode === 'learn-quiz') {
            navigate(`/learn-quiz/${topicId}`);
        }
    };

    if (loading) {
        return <div>Loading topics...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div style={styles.container}>
            <h1>Choose a Topic</h1>
            <div style={styles.topicList}>
                {topics.map((topic) => (
                    <div key={topic.id} style={styles.topicCard} onClick={() => handleTopicClick(topic.id)}>
                        <img src={topic.image_url} alt={topic.topic_name} style={styles.topicImage} />
                        <h3>{topic.topic_name}</h3>
                    </div>
                ))}
            </div>
        </div>
    );
};

const styles = {
    container: {
        textAlign: 'center',
        marginTop: '20px',
        fontFamily: 'Arial, sans-serif',
    },
    topicList: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: '20px',
        marginTop: '20px',
    },
    topicCard: {
        border: '1px solid #ddd',
        padding: '10px',
        borderRadius: '10px',
        width: '150px',
        cursor: 'pointer',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        backgroundColor: '#f9f9f9',
    },
    topicImage: {
        width: '100px',
        height: '100px',
        objectFit: 'cover',
        borderRadius: '50%',
    },
};

export default TopicList;