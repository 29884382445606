// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Learn from './components/Learn';
import Quiz from './components/Quiz';
import TopicList from './components/TopicList'; // New Component
import History from './components/History'; // Import the new History component
import LearnQuiz from './components/LearnQuiz';
function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/learn/:topicId" element={<Learn />} /> {/* Add route for learning vocabulary by topic */}
          <Route path="/quiz/:topicId" element={<Quiz />} />
          <Route path="/learn-quiz/:topicId" element={<LearnQuiz />} />
          <Route path="/history" element={<History />} />
          <Route path="/topics" element={ <TopicList />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;