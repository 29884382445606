// src/components/Home.js
import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
    return (
        <div style={{textAlign: 'center', marginTop: '20%'}}>
            <h1>Welcome to the Home Page</h1>
            <button style={{margin: '10px'}}>
                <Link to="/topics?mode=learn">Learn</Link> {/* Add query parameter */}
            </button>
            <button style={{margin: '10px'}}>
                <Link to="/topics?mode=quiz">Quiz</Link> {/* Add query parameter */}
            </button>
            <button style={{margin: '10px'}}>
                <Link to="/topics?mode=learn-quiz">Learn Quiz</Link> {/* Add query parameter */}
            </button>
        </div>
    );
};

export default Home;