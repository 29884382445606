// src/components/WatchPictureTypingWordQuiz.js
import React, { useState } from 'react';

const WatchPictureTypingWordQuiz = ({ question, image_url, correct_answer, handleAnswer }) => {
  const [userInput, setUserInput] = useState('');

  const handleSubmit = () => {
    const isCorrect = userInput.trim().toLowerCase() === correct_answer.toLowerCase();
    handleAnswer(isCorrect);
  };

  return (
    <div>
      <h2>{question}</h2>
      <img src={image_url} alt="Quiz Image" style={styles.image} />
      <div>
        <input 
          type="text"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          placeholder="Type your answer here"
          style={styles.input}
        />
        <button onClick={handleSubmit} style={styles.button}>
          Submit Answer
        </button>
      </div>
    </div>
  );
};

const styles = {
  image: {
    width: '300px',
    height: '300px',
    objectFit: 'cover',
    margin: '20px',
  },
  input: {
    padding: '10px',
    fontSize: '16px',
    marginRight: '10px',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#4CAF50',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default WatchPictureTypingWordQuiz;