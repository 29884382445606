// src/components/WatchWordChoosePictureQuiz.js
import React from 'react';

const WatchWordChoosePictureQuiz = ({ question, word, choices, correct_answer, handleAnswer }) => {
  return (
    <div>
      <h2>{question}</h2>
      <h3>{word}</h3>
      <div style={styles.choicesContainer}>
        {choices.map((choice, index) => (
          <img
            key={index}
            src={choice}
            alt="Choice"
            style={styles.choiceImage}
            onClick={() => handleAnswer(choice === correct_answer)}
          />
        ))}
      </div>
    </div>
  );
};

const styles = {
  choicesContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
    flexWrap: 'wrap',
  },
  choiceImage: {
    width: '150px',
    height: '150px',
    cursor: 'pointer',
    objectFit: 'cover',
    border: '2px solid transparent',
    borderRadius: '10px',
    transition: 'border-color 0.3s',
  },
};

export default WatchWordChoosePictureQuiz;