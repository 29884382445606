import React from 'react';

const FillInTheBlankQuiz = ({ question, sentence, choices, correct_answer, handleAnswer }) => {
  return (
    <div>
      <h2>{question}</h2>
      <p>{sentence}</p>
      <div style={styles.choicesContainer}>
        {choices.map((choice, index) => (
          <button
            key={index}
            style={styles.choiceButton}
            onClick={() => handleAnswer(choice === correct_answer)}
          >
            {choice}
          </button>
        ))}
      </div>
    </div>
  );
};

const styles = {
  choicesContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
    flexWrap: 'wrap',
  },
  choiceButton: {
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    transition: 'background-color 0.3s',
  },
};

export default FillInTheBlankQuiz;