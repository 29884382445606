// src/components/Result.js
import React, { useEffect } from 'react';

const Result = ({ score, quizTransactionId }) => {

    // useEffect to send the POST request when the component mounts
    useEffect(() => {
        const completeQuiz = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/complete_quiz`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        quiz_transaction_results_id: quizTransactionId,
                        score: score,
                    }),
                });

                const result = await response.json();
                if (result.status === 'success') {
                    console.log('Quiz completion recorded successfully!');
                } else {
                    console.error('Error completing quiz:', result.message);
                }
            } catch (error) {
                console.error('An error occurred while completing the quiz:', error);
            }
        };

        completeQuiz();
    }, [quizTransactionId, score]); // Depend on score and quizTransactionId

    return (
        <div style={styles.container}>
            <h1>Quiz Complete</h1>
            <p>Your Score: {score}</p>
        </div>
    );
};

// Styles for the result page
const styles = {
    container: {
        textAlign: 'center',
        marginTop: '20px',
        fontFamily: 'Arial, sans-serif',
    },
};

export default Result;